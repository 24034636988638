import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Import Screens
import HomeScreen from "./screens/HomeScreen";
import ProductsScreen from "./screens/ProductsScreen";
import CompaniesScreen from "./screens/CompaniesScreen";
import ContactScreen from "./screens/ContactScreen";

// Import components
import Navbar from "./components/Navbar/Navbar";

const App = () => {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={HomeScreen} />
          <Route path="/products" exact component={ProductsScreen} />
          <Route path="/companies" exact component={CompaniesScreen} />
          <Route path="/contact" exact component={ContactScreen} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
