import React from "react";

const ContactScreen = () => {
  return (
    <div className="main-container">
      <div style={{ fontWeight: 900, fontSize: 24, color: "#054c92" }}>
        Contact Us
      </div>

      <div>
        <div style={{ fontWeight: 400, fontSize: 16, color: "#1d1d1d" }}>
          Blue Eye Group Of Companies
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontWeight: 400,
            fontSize: 16,
            color: "#1d1d1d",
          }}
        >
          <span>Shantinagar-31, New Baneshwor, Kathmandu</span>
          <span>G.P.O: 1164</span>
        </div>

        <div style={{ fontWeight: 400, fontSize: 16, color: "#1d1d1d" }}>
          <span>Email:</span>
          <a
            style={{
              color: "#054c92",
              textDecoration: "none",
              paddingLeft: 5,
            }}
            href={"mailto:info@blueeye-group.com"}
          >
            info@blueeye-group.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactScreen;
